export function devTools() {
  if (process.env.ENV !== 'production') {
    window['enableDevtools'] = () => {
      localStorage.setItem('devtools', `true`)
      window.location.reload()
    }

    window['disableDevtools'] = () => {
      localStorage.setItem('devtools', `false`)
      window.location.reload()
    }
  }
}
